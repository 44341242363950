@media all and (min-width: 300px) {
  body {
    .comfort {
      font-size: 1.35em;
      h3 {
        margin-top: -0.75em !important;
      }

      :nth-child(n) {
        margin-top: 0;
      }
      .CircularProgress {
        position: relative;
        left: 0.6em;
      }
    }
    .flexContStatus,
    .flexContWind {
      font-size: 0.7em;
      height: fit-content;
    }
    .flexContStatus {
      width: fit-content;
      margin-right: auto;
      margin-left: auto;
    }
    .flexContWind {
      margin: 0 -0.25em;
    }
    .sunMoonCont {
      font-size: 1.1em;
      margin-bottom: 2em;
      :nth-child(2) {
        padding: 0 1.5em !important;
      }
    }

    .gridCont {
      font-size: 0.7em;
      width: fit-content;
    }
  }
}
@media all and (min-width: 300px) and (max-width: 361px) {
  #humidityValue {
    right: -26.5px !important;
  }
  #humidity {
    margin: 0 -1.5em !important;
  }
  .comfort {
    justify-content: space-between;
  }
  body .comfort .CircularProgress {
    position: relative;
    left: -0.6em;
  }
  .flexContStatus {
    width: 100% !important;
  }
}
@media all and (min-width: 362px) and (max-width: 400px) {
  body {
    .comfort {
      font-size: 1.2em;
      #humidity {
        margin: 0 0.5em;
      }
    }
    .flexContStatus {
      margin-left: auto;
      margin-right: auto;
      width: clamp(10em, 100%, 400%);
    }
    .flexContStatus,
    .flexContWind {
      font-size: 0.65em;
      height: fit-content;
    }
  }
}
@media all and (min-width: 401px) and (max-width: 499px) {
  .comfort .humidity {
    margin-right: 0;
  }
  .comfort #humidity {
    margin-left: 2.25em;
  }
}

@media all and (min-width: 500px) and (max-width: 900px) {
  body {
    :nth-child(n) {
      border: none !important;
    }
    .gridCont {
      :nth-child(1) {
        border-bottom: 2px solid rgb(90, 90, 90) !important;
        border-right: 2px solid rgb(90, 90, 90) !important;

        margin-right: -0.75em;
      }
      :nth-child(2) {
        border-bottom: 2px solid rgb(90, 90, 90) !important;
      }

      :nth-child(3) {
        border-top: 2px solid rgb(90, 90, 90) !important;
        border-right: 2px solid rgb(90, 90, 90) !important;

        margin-right: -0.75em;
      }
      :nth-child(4) {
        border-top: 2px solid rgb(90, 90, 90) !important;
        //margin-left: -0.5em;
      }
    }
  }
  .comfort {
    justify-content: space-between;
    font-size: 1.5em !important;
    #humidity {
      margin-left: 0.5em;
    }
    #humidityValue {
      right: -60.5px;
    }
  }
  .flexContStatus {
    font-size: large !important;
    width: 100% !important;
  }
  .flexContWind {
    font-size: 0.9em !important;
  }
  .sunMoon {
    font-size: 1.25em !important;
    justify-content: space-between !important;
    :nth-child(2) {
      line-height: initial !important;
    }
    :nth-child(n) {
      margin-top: 0.75em !important;
    }
  }
  .gridCont {
    font-size: 1em !important;
  }
}
@media all and (min-width: 901px) {
  body {
    zoom: 1.5;
    .gridCont {
      border: inherit !important;
    }
    :nth-child(n) {
      border: none !important;
    }
    .comfort {
      justify-content: space-around !important;
      #humidity {
        margin-left: 0.5em !important;
      }
    }
    .flexContStatus {
      font-size: 1em !important;
      width: 100%;
      justify-content: space-around !important;
    }
    .flexContWind {
      font-size: 0.8em;
      justify-content: space-around !important;
    }
    .sunMoon {
      justify-content: space-around !important;
      :nth-child(n) {
        margin-top: 0.75em !important;
      }
      :nth-child(2) {
        line-height: initial !important;
      }
      margin-bottom: initial !important;
    }
    .gridCont {
      width: 100%;
      :nth-child(1) {
        border-bottom: 2px solid rgb(90, 90, 90) !important;
        border-right: 2px solid rgb(90, 90, 90) !important;

        margin-right: -0.75em;
      }
      :nth-child(2) {
        border-bottom: 2px solid rgb(90, 90, 90) !important;
      }

      :nth-child(3) {
        border-top: 2px solid rgb(90, 90, 90) !important;
        border-right: 2px solid rgb(90, 90, 90) !important;

        margin-right: -0.75em;
      }
      :nth-child(4) {
        border-top: 2px solid rgb(90, 90, 90) !important;
        //margin-left: -0.5em;
      }
    }
    :nth-child(15) {
      //Geographical Coordinates🠻
      white-space: pre;
    }
    // active height normalisation

    .comfort.active {
      height: 0.1em;
    }
    .flexContWind.active {
      height: 4em;
    }
    .sunMoon.active {
      height: 2.75em;
    }
    .gridCont.active {
      height: 3em;
    }
  }
}
