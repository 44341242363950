@import url("https://fonts.googleapis.com/css2?family=Smooch&display=swap");
* {
  scroll-behavior: smooth;
}
body {
  background-color: rgb(173, 173, 173);
  color: whitesmoke;
  font-family: cursive;
  background-repeat: no-repeat;
  height: 120vh;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

.core {
  text-align: center;

  .country {
    font-family: "Smooch", cursive;
  }

  .dateString {
    margin-top: -1.5em;
  }

  @keyframes flagAnimation {
    0% {
      opacity: 0;
      transform: rotate(0deg);
    }
    50% {
      transform: rotateY(180deg);
    }
    100% {
      opacity: 1;
      transform: rotate(0deg);
    }
  }
  @keyframes flagAnimationReverse {
    100% {
      opacity: 0;
      transform: rotate(0deg);
    }
    50% {
      transform: rotateY(-180deg);
    }
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }
  }

  .flagImg {
    width: 10em;
    margin-bottom: 0.5em;
    border-radius: 1em;
    animation-name: flagAnimationReverse;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-direction: reverse;
  }

  .flagImg.active {
    animation-name: flagAnimation;
    animation-duration: 0.5s;
    animation-direction: alternate;
    animation-iteration-count: 1;
  }

  input {
    font-family: cursive;
    display: block;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    //margin-top: 2em;
    font-size: 1.5em;
    width: 80%;
    padding: 1em;
    border-radius: 0.5em;
    border: none;
    text-align: center;
    top: -20px;
    box-shadow: 0 0 1em 0 white;
  }

  h1 {
    font-size: 3.5em;

    font-family: "Smooch", cursive;
  }

  .nestedSpans {
    font-size: 85%;
    display: block;
  }

  h1 {
    span {
      font-size: 40% !important;
      margin: 2.5% 0;
    }
  }
  .temp {
    background-color: rgba(255, 255, 255, 0.281);
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    padding: 0.25em 1.25em;
    border-radius: 0.2em;

    span {
      font-family: cursive;
    }
    font-family: "Smooch", cursive;
  }
}

.flexCont {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  :nth-child(1) {
    border-right: 3px solid rgb(90, 90, 90);
    padding-right: 0.5em;
  }
  :nth-child(2) {
    border-right: 3px solid rgb(90, 90, 90);
    padding: 0 0.75em;
    margin-right: 0.5em;
  }
}
.dateHandler {
  :nth-child(n) {
    padding: 0.75em 0;
    margin: -1em;
  }
}
.status {
  font-size: 2em;
  width: min-content;
  margin-left: auto;
  margin-right: auto;
  padding: 0.25em 0.75em;
  border-radius: 0.2em;
}
.status:hover {
  background-color: rgba(65, 65, 65, 0.233);
  cursor: pointer;
}
.status:active {
  border: 4px solid rgba(204, 204, 204, 0.561);
}

.flexContStatus {
  background-color: rgba(128, 128, 128, 0.246);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 2em;
  margin: 1em;
  height: 6.5em;
  transition: all 0.5s ease-in-out;

  :nth-child(n) {
    transition: all 0.5s ease-in-out;
  }

  div {
    font-weight: bolder;
    padding: 0 2em;
    font-size: 1.4em;
    h3 {
      font-size: initial;
    }
  }
  div:nth-child(1) {
    border-right: 3px solid rgb(90, 90, 90);
    padding-top: 0.75em;
  }
  div:nth-child(2) {
    border-right: 3px solid rgb(90, 90, 90);
    padding-top: 0.75em;
  }
  div:nth-child(3) {
    padding-top: 0.75em;
  }
}

.flexContStatus.active {
  height: 3em;
  border: 2px solid rgba(194, 194, 194, 0.356);
  width: 95%;
  margin-left: auto;
  margin-right: auto;

  div {
    opacity: 0;
    font-size: 0;
    transition: all 0.25s ease-in-out;
  }
}

.flexContWind {
  background-color: rgba(128, 128, 128, 0.246);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 2em;
  margin: 1em;
  padding: 0 2em;
  height: 8em;
  transition: all 0.5s ease-in-out;

  :nth-child(n) {
    transition: all 0.5s ease-in-out;
  }
  div {
    font-size: 1.75em;
    font-weight: bolder;
    position: relative;
    opacity: 1;

    h4 {
      font-size: 0.725em;
      font-weight: 600;
    }
  }
  div:nth-child(1) {
    border-right: 3px solid rgb(90, 90, 90);
    padding-top: 0.5em;
    padding-right: 2em;
  }
  div:nth-child(2) {
    border-right: 3px solid rgb(90, 90, 90);
    padding: 0 1em;
    padding-top: 0.5em;
    white-space: pre;
  }
  div:nth-child(3) {
    padding-top: 0.5em;
    padding-left: 2em;
  }
}

.flexContWind.active {
  height: 3em;
  border: 2px solid rgba(194, 194, 194, 0.356);
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  div {
    opacity: 0;
  }
  :nth-child(n) {
    font-size: 0em;
    transition: all 0.25s ease-in-out;
  }
}

.comfort {
  background-color: rgba(128, 128, 128, 0.246);
  border-radius: 1em;
  font-size: 1.5em;
  padding: 1em;
  height: 2em;
  transition: all 0.25s ease-in-out;
  font-weight: bolder;
  display: flex;
  justify-content: space-between;
  h3 {
    margin-bottom: 0;
  }
  :nth-child(n) {
    margin-top: 0.25em;
    transition: all 0.5s ease-in-out;
  }
  #humidity {
    position: relative;
    top: -12px;
    margin-left: 0.25em;
  }
  #humidityValue {
    position: relative;
    right: -52.5px;
    top: -14px;
    font-size: 0.75em;
  }
  .CircularProgress {
    width: 2em !important;
  }
  .css-1rn30mb-MuiCircularProgress-root {
    // CircularProgress Color
    color: rgb(0, 255, 136);
  }
  .humidity {
    margin-left: -0.5em;
    margin-right: 1.5em;
    align-self: center;
  }
}
.comfort.active {
  border: 2px solid rgba(194, 194, 194, 0.356);

  height: 0em;

  :nth-child(n) {
    opacity: 0;
    font-size: 0em;
    transition: all 0.25s ease-in-out;
  }
}

.gridCont {
  background-color: rgba(128, 128, 128, 0.246);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 3.5em;
  border-radius: 2em;
  gap: 1em;
  height: 13em;
  margin-right: auto;
  margin-left: auto;
  width: 65%;
  padding-bottom: 1em;
  transition: all 0.25s ease-in-out;
  margin-bottom: 3em;

  //this is for the top part

  :nth-child(n) {
    opacity: 1;
    transition: all 0.25s ease-in-out;
  }
  :nth-child(1) {
    border-bottom: 2px solid rgb(90, 90, 90);
    margin-right: -0.75em;
  }
  :nth-child(2) {
    border-bottom: 2px solid rgb(90, 90, 90);
  }

  :nth-child(3) {
    border-top: 2px solid rgb(90, 90, 90);
    margin-right: -0.75em;
  }
  :nth-child(4) {
    border-top: 2px solid rgb(90, 90, 90);
    //margin-left: -0.5em;
  }
  // X AXIS

  //THIS IS Y AXIS
  :nth-child(1) {
    border-right: 2px solid rgb(90, 90, 90);
    margin-right: -0.75em;
  }
  :nth-child(3) {
    border-right: 2px solid rgb(90, 90, 90);
    margin-right: -0.75em;
  }
}
.gridCont.active {
  border: 2px solid rgba(194, 194, 194, 0.356);

  height: 2em;
  transition: all 0.25s ease-in-out;

  width: 60%;
  margin-right: auto;
  margin-left: auto;
  :nth-child(n) {
    opacity: 0;
    font-size: 0;
  }
}

.sunMoonCont {
  .sunMoon {
    display: flex;
    justify-content: center;
    border-radius: 1em;
    background-color: rgba(128, 128, 128, 0.246);
    transition: all 0.25s ease-in-out;
    height: 5em;
    padding: 0 1em;

    :nth-child(n) {
      transition: all 0.5s ease-in-out;
      opacity: 1;
      font-size: 1em;
      margin-top: 0em;
    }
    h3:not(:nth-child(3)) {
      //all childs except the 3rd one
      border-right: 2px solid rgb(90, 90, 90);
      height: 100%;
    }
    h3:nth-child(2) {
      padding: 0 3em;
      line-height: 2em;
    }
  }
}

.sunMoon.active {
  height: 1.25em;
  border-radius: 2em;
  margin-bottom: 5em;
  transition: all 0.5s ease-in-out;
  margin-right: auto;
  margin-left: auto;
  width: 70%;
  height: 3em;
  margin-bottom: initial;
  border: 2px solid rgba(194, 194, 194, 0.356);

  :nth-child(n) {
    opacity: 0;
    font-size: 0;
    transition: all 0.5s ease-in-out;
  }
}
